import React from 'react';
import styled from 'styled-components';
import Layout from "../components/Layout";
import { graphql, useStaticQuery, Link } from "gatsby";
import { StaticImage, getImage } from "gatsby-plugin-image";
import { breakpoints } from '../styles/Breakpoints';
import { Helmet } from "react-helmet";

const PageHeader = styled.div`
    margin: 100px 0 40px;
    @media (min-width: ${breakpoints.small}) {
        margin: 140px 0 68px;
    }
    h1 {
        font-size: 2rem;
        color: var(--green);
        text-align: center;
        padding: 0 20px;
        line-height: 1.3;
    }
`

const AboutContainer = styled.div`
    max-width: 990px;
    padding: 0 40px;
    margin: 0 auto 40px;
    display: flex;
    flex-direction: column;
    @media (min-width: ${breakpoints.small}) {
        flex-direction: row;
        align-items: center;
        margin-bottom: 80px;
    }
    .pic-wrapper {
        border-radius: 12px;
        margin: 0 auto 60px;
        max-width: 320px;
        z-index: 0;
        @media (min-width: ${breakpoints.small}) {
            margin: 0 60px 0 0;
            min-width: 250px;
        }
    }
    h2 {
        font-size: 1.5rem;
    }
    @media (min-width: ${breakpoints.small}) {
        .about-content {
            max-width: 550px;
        }
    }
`

const About = () => {
    return (
        <Layout alwaysSticky={true} currentPage={"about"}>
            <Helmet>
                <title>About :: Tide &amp; Tone Photography</title>
            </Helmet>
            <PageHeader>
                <h1 className="subpage-title">About<br className="mobile-show"/> Tide &amp; Tone</h1>
            </PageHeader>
            <AboutContainer>
                <StaticImage 
                    src="../images/site-assets/about-pic-1.jpg"
                    alt="Chad Parker"
                    width={600}
                    className="pic-wrapper"
                />
                <div className="about-content">
                    <h2>Hi, I'm Chad Parker</h2>
                    <p>I’m a father, a husband, and I love capturing moments to remember. I’ve lived in Wilmington, North Carolina for over fifteen years, and the scenery in this area has inspired me to engulf myself into the art of photography.</p>
                    <p>As a graphic designer by trade, I have a passion for color and composition, and I’m excited to put these skills to work to capture you and those you love in moments that matter.</p>
                    <p>I’m currently booking minis, half-hour, and full hour photo sessions. If you have any questions or would like to book a session, scroll down and leave me a note!</p>
                </div>
            </AboutContainer>
        </Layout>
    )
}
export default About;